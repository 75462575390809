import React from 'react';

export default function Crumb(props: any) {
  const { color, name, id, status, data, bearing, opacity } = props;
  const { handleClick } = props;

  return <div
    className={status ?? "dot"}
    title={name}
    style={{
      transform: 'rotate(' + (+bearing) + 'deg)',
      opacity: opacity ?? 1,
      backgroundColor: color ?? '#444'
    }}
    // onClick={() => handleClick(data)}
  />;
};

