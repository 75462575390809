import { Close, DirectionsBus, GpsFixed, GpsNotFixed, LocationOn, MyLocation, Subway, Train, TrainRounded, TrainSharp, TrainTwoTone, Tram } from "@mui/icons-material";
import { AppBar, Box, Button, IconButton, Toolbar, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function NavBar(props: any) {

  const { getClientPosition, focusedVehicle, isLockedToVehicle, emptyVehicle, showBuses, setIsLockedToVehicle, setfocusedVehicle, setShowBuses, numTrains, numBuses, toggleDrawer, clientPosition, centerMap, isLocateClientEnabled } = props;

  const [time, setTime] = useState<string[]>(['', '']);

  const getTime = () => {
    let d = new Date();
    return d.toLocaleString('en-US', { timeZone: 'America/New_York' }).split(/, /);
  }

  useEffect(() => {
    let interval = setInterval(() => setTime(getTime()), 1000);
    return () => { clearInterval(interval) }
  }, [time, setTime])

  return (
    <AppBar color={focusedVehicle.id ? "secondary" : "primary"}>
      <Toolbar>
        {focusedVehicle.id.length ?
          <>
            <Button color="inherit" aria-label="open drawer" onClick={() => toggleDrawer()}>
              <img src="wing.svg" style={{ height: "24px" }} />
            </Button>
            <Button color="inherit"  >
              {focusedVehicle.id.toUpperCase()}
              &nbsp;|&nbsp;
              {focusedVehicle.attributes.current_status.replace(/_AT$|_TO$/, '').replaceAll('_', ' ')}
            </Button>

            <Box sx={{ flexGrow: 1 }} />
            <div className="hide-xs"            >
              <Button
                color="inherit"
                onClick={() => { setIsLockedToVehicle(!isLockedToVehicle) }}
                startIcon={isLockedToVehicle ? <GpsFixed /> : <GpsNotFixed />}
              >
                {isLockedToVehicle ? 'Lock' : 'Lock'}
              </Button>
            </div>
            <div className="show-xs"            >
              <IconButton
                color="inherit"
                onClick={() => { setIsLockedToVehicle(!isLockedToVehicle) }}
              >
                {isLockedToVehicle ? <GpsFixed /> : <GpsNotFixed />}
              </IconButton>
            </div>
            <IconButton onClick={() => setfocusedVehicle(emptyVehicle)} >
              <Close />
            </IconButton>
          </>
          :
          <>
            <Button color="inherit" aria-label="open drawer">
              <img src="wing.svg" style={{ height: "24px" }} />
            </Button>
            <Typography variant="subtitle2" sx={{ fontSize: 22, fontFamily: "Roboto Condensed", fontWeight: 'bold', 'marginTop': '4px' }} className="hide-xxs">
              MBTAngel
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            {/* <Typography variant="subtitle2" sx={{ minWidth: '100px' }}>
              {(vehicles ? vehicles.length : 0) + ' Vehicle' + (vehicles && vehicles.length === 1 ? '' : 's')}
            </Typography> */}
            <ToggleButton
              value="trains"
              style={{ color: 'white', border: 0 }} >
              <Train />
              <span className="show-xs">×</span>
              <span className="hide-xs">&nbsp;</span>
              <span>{numTrains}</span>
              <label>Trains</label>
            </ToggleButton>

            {clientPosition && isLocateClientEnabled && <>
              {/* <Button
                className="hide-xs"
                startIcon={<MyLocation />}
                aria-label="find me"
                style={{ color: 'white' }}
                onClick={() => {
                  getClientPosition();
                  if (isLocateClientEnabled)
                    centerMap(clientPosition?.coords?.latitude, clientPosition?.coords?.longitude);
                }}
              >
                Find Me
              </Button> */}
              <IconButton
                aria-label="find me"
                onClick={() => {
                  getClientPosition();
                  if (isLocateClientEnabled)
                    centerMap(clientPosition?.coords?.latitude, clientPosition?.coords?.longitude);
                }}
                className="show-xs"
              >
                <MyLocation />
              </IconButton>
            </>}

            {/* <ToggleButtonGroup
              size="small"
              value={showBuses ? 'trains' : 'buses'}
              exclusive
              onClick={() => setShowBuses(!showBuses)}
            >
              <ToggleButton
                value="trains"
                style={{ color: 'white' }} >
                <Train />
                <span className="show-xs">×</span>
                <span className="hide-xs">&nbsp;</span>
                <span>{numTrains}</span>
                <label>Trains</label>
              </ToggleButton>
              <ToggleButton
                value="buses"
                style={{ color: 'white' }} >
                <DirectionsBus />
                <span className="show-xs">×</span>
                <span className="hide-xs">&nbsp;</span>
                <span>{numBuses}</span>
                <label>Buses</label>
              </ToggleButton>
            </ToggleButtonGroup> */}
          </>
        }

        {/* <Typography variant="subtitle2" sx={{ fontFamily: "Roboto Condensed", fontWeight: 'bold', marginLeft: '1rem' }}>
          {time[1]}
        </Typography> */}
      </Toolbar>
    </AppBar >

  )
}