import { Close } from "@mui/icons-material";
import { IconButton, Card, CardContent, Divider, Typography, CardActions, Button } from "@mui/material";



function capitalizeFirstLetter(s: string): string { return s.charAt(0).toUpperCase() + s.slice(1); }

export default function VehicleCard(props: any) {
  const { style, vehicle, nextStop, handleClose, handleFocus, handleLocate, handleViewRoute } = props;

  return <Card
    sx={{
      width: '300px',
      ...style
    }}
    elevation={4}
  >
    {/* <IconButton
      size="small"
      onClick={handleClose}
      style={{ position: 'absolute', right: 6, top: 12 }}
    >
      <Close />
    </IconButton>
 */}
    <CardContent>
      <Typography variant="h6" >
        {
          (vehicle.relationships.route.data.id.match(/^[0-9]/) ? "Route " : " ") +
          vehicle.relationships.route.data.id.replace('-', ' ').replace(/(^CR) (.*)/, "$2 Commuter")
        }
      </Typography>

      <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto Condensed' }} gutterBottom >
        {
          capitalizeFirstLetter(
            vehicle.attributes.current_status
              .replaceAll('_', ' ')
              .toLowerCase()
            + " "
          )
        } <span style={{ whiteSpace: 'nowrap' }}>{nextStop?.attributes.name}</span>
      </Typography>

      <Typography variant="subtitle2" color="text.secondary" sx={{ fontFamily: 'Roboto Condensed' }}>
        Vehicle {vehicle.attributes.label} 
        {/* · Stop {vehicle.attributes.current_stop_sequence} */}
      </Typography>

      {vehicle.attributes?.occupancy_status &&
        <Typography variant="subtitle2" color="text.secondary" sx={{ fontFamily: 'Roboto Condensed' }}>
          {vehicle.attributes.occupancy_status.replaceAll('_', ' ')}
        </Typography>
      }

    </CardContent>

    <CardActions>
      {/* <Button size="small" onClick={() => handleFocus(vehicle)}>Follow</Button> */}
      <Button size="small" onClick={() => handleLocate(vehicle)}>Locate</Button>
      {/* <Button size="small" onClick={() => handleViewRoute(vehicle.relationships.route.data)}>View Route</Button> */}

      <span className="fill-space" />
      <IconButton size="small" color="inherit" onClick={handleClose}><Close /></IconButton>
    </CardActions>
  </Card>;
}