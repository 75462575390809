import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close } from "@mui/icons-material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Chip from '@mui/material/Chip';

export default function Marker(props: any) {
  const { color, name, id, type, data, fade, focus } = props;
  const { handleClick } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={type ?? "vehicle"}
      style={{
        cursor: 'pointer',
        opacity: fade ? '0.5' : '1',
        borderRadius: '100%',
        backgroundColor: focus ? 'rgba(255,255,255,0.5)' : '',
        boxShadow: focus ? '0px 2px 10px rgba(0,0,0,0.2)' : '',
        backdropFilter: focus ? 'blur(2px)' : '',
        zIndex: focus ? '5' : '1',
        padding: '12px',
        overflow: 'show'
      }}
      title={name}
      onClick={(e) => { e.stopPropagation(); handleClick(data); }}
    >
      <img
        // aria-owns={open ? 'mouse-over-popover' : undefined}
        // aria-haspopup="true"
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}      
        style={{
          transform: 'rotate(' + ((+data?.attributes?.bearing - 90)) + 'deg) scale(' + (focus ? '1.25' : fade ? '0.75' : '1') + ')',
          filter: 'saturate(' + (fade ? '0' : '1') + ')',
        }}
        src={'markers/' + data?.attributes?.current_status.toLowerCase() + "/" + (type ?? "vehicle") + ".svg"}
        onClick={(e) => { e.stopPropagation(); handleClick(data); }}
      />
      {/* <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        // disableRestoreFocus
      >
        <Typography sx={{ p: 0.6 }} variant="body2">{data.relationships.route.data.id} {data.attributes.label}</Typography>
      </Popover> */}
      {/* {focus && <Chip
        style={{
          position: 'absolute',
          left: data?.attributes?.bearing <= 180 ? '50px' : '',
          right: data?.attributes?.bearing > 180 ? '50px' : '',
          top: '10px',
          height: '24px',
          color: 'rgba(0,0,0,0.6)',
          backgroundColor: 'rgba(255,255,255,0.8)',
          boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
          backdropFilter: 'blur(5px)',
        }}
        label={data.attributes.label}
      />} */}
    </div>
  );
};

