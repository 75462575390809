import { Typography } from "@mui/material";

export default function RouteTitle(props: any): any {
  const { route } = props;

  return <>
    <span style={{ color: '#' + route.attributes.color }}>
      {route.attributes.long_name}
    </span>

    <Typography variant="subtitle2">
      {route.attributes.fare_class}
    </Typography>

    <Typography variant="body1">
      {route.attributes.direction_destinations.map((x: string) => <span>{x}</span>)}
    </Typography>
  </>;
}

