
import { Drawer, List, ListItem } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import './App.css';
import GoogleMap from './Map';

function App() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clientPosition, setClientPosition] = useState<object|null>();
  const [hasLocationPermission, setHasLocationPermission] = useState(false);

  const toggleDrawer = useCallback(() => {
    setOpenDrawer(!openDrawer);
  }, [openDrawer, setOpenDrawer]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setHasLocationPermission(true);
      setClientPosition(position);
    });
  }, [,clientPosition, setClientPosition]);

  const getClientPosition = useCallback(()=>{
    navigator.geolocation.getCurrentPosition(function (position) {
      setHasLocationPermission(true);
      setClientPosition(position);
    });
  },[clientPosition, setClientPosition]);

  return (
    <div className="App">
      {/* <TitleBar title="MBTAngel"/> */}
      {/* <LinearDeterminate/> */}
      {/* <Drawer
        anchor='left'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem>
          </ListItem>
        </List>
      </Drawer> */}

      <GoogleMap handleDrawer={toggleDrawer} clientPosition={clientPosition} getClientPosition={getClientPosition}/>
    </div >
  );
}

export default App;
