import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton } from "@mui/material";
import RouteTimeline from "./parts/RouteTimeline";
import Red from "../data/stops/Red";
import { ReactHTMLElement, useRef } from 'react';
import { Close } from '@mui/icons-material';
import RouteTitle from '../components/parts/RouteTitle';

const stops = Red.data;

export default function ViewRouteDialog(props: any): any {
  const { route, open, onClose } = props;

  const contentRef = useRef();

  return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {<DialogTitle id="alert-dialog-title" >
      <RouteTitle route={route}/>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>}
    {/* {contentRef?.current && <Divider />} */}
    <DialogContent ref={contentRef}>
      <RouteTimeline stops={stops}/>
    </DialogContent>
  </Dialog>
}

