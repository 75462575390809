import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { MbtaStop } from '../../interfaces/Stop';

export default function RouteTimeline({ stops }: { stops: MbtaStop[] }): any {
  return <Timeline position="alternate">
    {stops.map((e: MbtaStop, i: number, a: MbtaStop[]) =>
      <TimelineItem key={'timeline-stop-'+e?.id}>
        <TimelineSeparator>
          <TimelineDot sx={{ backgroundColor: 'red' }} />
          {i !== a.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent sx={{ fontFamily: 'Roboto Condensed', fontSize: '11pt' }}>
          {e?.attributes.name?.replace('/', ' / ')}
        </TimelineContent>
      </TimelineItem>
    )}
  </Timeline>;
};