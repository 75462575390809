import { useEffect, useState, useCallback } from 'react';
import { Service } from '../types/Service';
import { Vehicle } from '../interfaces/Vehicle';
import { CrumbHistory } from '../interfaces/CrumbHistory';
import { MbtaStop } from '../interfaces/Stop';

export interface MbtaData {
  data: any[];
}

// const maxCrumbs = 100;
const defaultInterval = 5000;
const throttledInterval = 60000;
const url = `https://api-v3.mbta.com/`;



export const MbtaRoutes = Object.freeze({
  vehicles: "vehicles"
});


export default function useMbta(path: string = '', interval: number = 0) {

  const [result, setResult] = useState<Service<MbtaData>>({ status: 'loading' });
  const [intervalMs, setIntervalMs] = useState<number>(interval);
  const [lastQueryAt, setLastQueryAt] = useState<number | null>();

  const fetchDataAtInterval = useCallback(() => {
    if (!intervalMs) return;
    setResult(() => ({ ...result, status: 'loading' }));
    // console.log(`fetching ${path} ${Date.now()} ${intervalMs}`);
    if (!lastQueryAt || (Date.now() - lastQueryAt) > intervalMs)
      fetch(url + path)
        .then(response => response.json())
        .then(response => {
          setIntervalMs(interval);
          setResult({ status: 'loaded', payload: response })
          // console.log(response)
        })
        .catch(error => {
          setIntervalMs(throttledInterval);
          setResult({ status: 'error', error })
        }).finally(() => {
          setLastQueryAt(Date.now());
          // lastQueryAt && console.log('Fetched @ ' + new Date(lastQueryAt).toString().replace(/ GMT.*/, ''));
        });
    else console.log('queried too soon')
  }, [defaultInterval, throttledInterval]);

  const fetchData = useCallback(() => {
    setResult(() => ({ ...result, status: 'loading' }));
    // console.log(`fetching ${path} ${Date.now()}`);
    fetch(url + path)
      .then(response => response.json())
      .then(response => setResult({ status: 'loaded', payload: response }))
      .catch(error => setResult({ status: 'error', error }));
  }, []);


  useEffect(() => {
    if (intervalMs) {
      const interval = setInterval(fetchDataAtInterval, intervalMs)
      return () => clearInterval(interval);
    };
  }, [result, setResult, intervalMs])


  useEffect(() => fetchData(), [])

  return result; //{ ...result, history };
};

