import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import Crumb from './Crumb';
import React from 'react';
import useMbta, { MbtaRoutes } from './services/mbta-service';
import { Snackbar, Fab } from '@mui/material';
import googleMapReact from 'google-map-react';
import { Vehicle } from './interfaces/Vehicle';
import mapStyles from './config/map-styles';
import { CrumbHistory } from './interfaces/CrumbHistory';
import NavBar from './components/NavBar';

import { MbtaStop } from './interfaces/Stop';
import { MbtaRoute } from './interfaces/Route';

import routes from './data/routes';
import lines from './data/lines';
import Stops from './data/stops';
import VehicleCard from './components/VehicleCard';
import { Close, Search, MoreHoriz } from '@mui/icons-material';
import ViewRouteDialog from './components/ViewRouteDialog';


const defaultProps = {
  center: {
    lat: 42.3601,
    lng: -71.0589
  },
  zoom: 11
};


const emptyVehicle: Vehicle = {
  "id": "",
  "type": "",
  "attributes": {
    "bearing": 0,
    "current_status": '',
    "current_stop_sequence": 0,
    "direction_id": 0,
    "label": '',
    "latitude": 0,
    "longitude": 0,
    "occupancy_status": '',
    "speed": 0,
    "updated_at": ''
  },
  "relationships": {
    "route": {
      "data": {
        "id": ""
      }
    },
    "stop": {
      "data": {
        "id": ''
      }
    }
  }

};

const maxCrumbs = 100;
const stops: MbtaStop[] = Stops.data;


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}


const mapOptions: googleMapReact.MapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: true,
  fullscreenControl: false,
  styles: mapStyles.default
};

const permittedMapBounds = {
  maxLat: 44.55479268740663,
  minLng: -74.5305796875,
  minLat: 40.08598708353708,
  maxLng: -67.5872203125
};

export default function GoogleMap(props: any) {

  const { toggleDrawer, clientPosition, getClientPosition } = props;

  const service = useMbta(MbtaRoutes.vehicles, 5000);


  const [showBuses, setShowBuses] = React.useState<boolean>(true);
  const [showLines, setShowLines] = React.useState<boolean>(true);
  const [mapYOffset, setMapYOffset] = React.useState<number>(getWindowDimensions().width >= 600 ? 60 : 54);
  const [mapHeight, setMapHeight] = React.useState<number>(getWindowDimensions().height - (getWindowDimensions().width >= 600 ? 64 : 56));
  const [focusedVehicle, setFocusedVehicle] = React.useState<Vehicle>(emptyVehicle);
  const [showInfoAboutVehicle, setShowInfoAboutVehicle] = React.useState<Vehicle>(emptyVehicle);
  const [nextStop, setNextStop] = React.useState<MbtaStop | undefined>();
  const [map, setMap] = React.useState<any | null>();
  const [mapProperties, setMapProperties] = React.useState<any | null>();
  const [maps, setMaps] = React.useState<any>();
  const [polylines, setPolylines] = React.useState<any[]>([]);
  const [numBuses, setNumBuses] = React.useState<number>(0);
  const [numTrains, setNumTrains] = React.useState<number>(0);
  const [vehicles, setVehicles] = React.useState<Vehicle[]>();
  const [crumbHistory, setCrumbHistory] = React.useState<CrumbHistory>({});
  const [isLockedToVehicle, setIsLockedToVehicle] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [showRoute, setShowRoute] = React.useState<boolean>(false);
  const [isLocateClientEnabled, setIsLocateClientEnabled] = React.useState<boolean>(false);

  // ON EVERY QUERY
  React.useEffect(() => {
    if (service.status === 'loaded' && service?.payload?.data) {
      setVehicles(service.payload.data.filter((e: any) => showBuses ? e.relationships.route.data.id.match(/^[RGCOB]/) : e.relationships.route.data.id.match(/^[0-9]/)));
      setNumBuses(service.payload.data.filter((e: any) => e.relationships.route.data.id.match(/^[0-9]/)).length)
      setNumTrains(service.payload.data.filter((e: any) => !e.relationships.route.data.id.match(/^[0-9]/)).length);
      if (focusedVehicle.id)
        setFocusedVehicle(service.payload.data.filter((e: Vehicle) => e.id === focusedVehicle.id)[0] || emptyVehicle);
      if (showInfoAboutVehicle.id) {
        setShowInfoAboutVehicle(service.payload.data.filter((e: Vehicle) => e.id === showInfoAboutVehicle.id)[0] || emptyVehicle);
        try { setNextStop(stops.filter(x => x.id === showInfoAboutVehicle?.relationships.stop.data.id)[0]); } catch (e) { ; }
        if (!isLatLngInbounds(showInfoAboutVehicle.attributes.latitude, showInfoAboutVehicle.attributes.longitude, mapProperties.bounds))
          map.panTo({ lat: showInfoAboutVehicle.attributes.latitude, lng: showInfoAboutVehicle.attributes.longitude });
      }

      for (const e of service.payload.data)
        if (!crumbHistory[e.id] || crumbHistory[e.id].findIndex(x => x.latitude === e.attributes.latitude && x.longitude === e.attributes.longitude) === -1)
          crumbHistory[e.id] = [{
            id: e.attributes.label,
            latitude: e.attributes.latitude,
            longitude: e.attributes.longitude,
            bearing: e.attributes.bearing,
            current_status: e.attributes.current_status,
            direction_id: e.attributes.direction_id,
            color: e.relationships.route.data.id.replace(/-.*/, '').toLowerCase().replace('cr', 'purple').replace('orange', 'darkorange').replace('blue', 'royalblue')
          }, ...crumbHistory[e.id] || []]
            .filter((c, i, a) => c.direction_id === a[0].direction_id)
            .slice(0, maxCrumbs);

      setCrumbHistory(crumbHistory);
      setIsLocateClientEnabled(clientPosition?.coords?.latitude != null && isLatLngInbounds(clientPosition?.coords?.latitude, clientPosition?.coords?.longitude));
    }
  }, [
    service?.status,
    setVehicles,
    setNumBuses,
    setNumTrains,
    showBuses,
    focusedVehicle,
    setFocusedVehicle,
    showInfoAboutVehicle,
    setShowInfoAboutVehicle,
    mapProperties,
    setMapProperties,
    setIsLocateClientEnabled,
    isLocateClientEnabled
  ]);


  // ON TOGGLE SHOW BUSES/TRAINS
  React.useEffect(() => {
    setOpenSnackbar(true);
    setShowInfoAboutVehicle(emptyVehicle);
    setFocusedVehicle(emptyVehicle);
  }, [showBuses])


  const isMapInbounds = (bounds: any): boolean =>
    bounds.nw.lat < permittedMapBounds.maxLat && bounds.nw.lng > permittedMapBounds.minLng &&
    bounds.se.lat > permittedMapBounds.minLat && bounds.se.lng < permittedMapBounds.maxLng

  const isLatLngInbounds = (lat: number | null, lng: number | null, bounds = permittedMapBounds): boolean => 
    (typeof lat === 'number' && typeof lng === 'number') && (lat < bounds.maxLat) && (lat > bounds.minLat) && (lng < bounds.maxLng) && (lng > bounds.minLat)
  

  // HANDLE VEHICLE MARKER CLICKED
  const handleMarkerClicked = React.useCallback((vehicle: Vehicle) => {
    if (!vehicle.id) console.log('missing id');
    if (!vehicle.id) return;

    if (!focusedVehicle.id.length)
      setShowInfoAboutVehicle(vehicle)
    else
      setShowInfoAboutVehicle(emptyVehicle)
    setIsLockedToVehicle(true);
    try {
      if (vehicle?.id.length)
        setNextStop(stops.filter(x => x.id === vehicle.relationships.stop.data.id)[0]);
    } catch (e) { ; }
  }, [setShowInfoAboutVehicle, showInfoAboutVehicle, focusedVehicle, setIsLockedToVehicle, isLockedToVehicle, setNextStop, nextStop])



  // HANDLE VIEW ROUTE
  const handleViewRoute = React.useCallback((route: MbtaRoute) => setShowRoute(true), [showRoute, setShowRoute]);

  // HANDLE FOCUS VEHICLE 
  const handleFocusVehicle = React.useCallback((vehicle: Vehicle) => {
    setFocusedVehicle(vehicle);
    setShowInfoAboutVehicle(emptyVehicle);
  }, [setFocusedVehicle, focusedVehicle, setShowInfoAboutVehicle, showInfoAboutVehicle])


  // ON MAP CLICK
  const handleMapClick = React.useCallback(() => setShowInfoAboutVehicle(emptyVehicle), [setShowInfoAboutVehicle, showInfoAboutVehicle]);

  // ON MAP ZOOM CHANGE
  const handleZoomChange = React.useCallback((e) => {
    // setShowInfoAboutVehicle(emptyVehicle);
  }, []);



  // ON EVERY MAP CHANGE
  const handleMapChange = React.useCallback((e) => {
    setMapProperties(e);

    // KEEP MAP IN BOUNDS
    if (!isMapInbounds(e.bounds))
      map.panTo({ lat: defaultProps.center.lat, lng: defaultProps.center.lng });

    // PREVENT MAP FROM ZOOMING OUT TOO MUCH
    if (e.zoom < 9) map.setZoom(9)

    setMapHeight(getWindowDimensions().height - (getWindowDimensions().width >= 600 ? 60 : 56));

    if (showInfoAboutVehicle.id && (!isLatLngInbounds(showInfoAboutVehicle.attributes.latitude, showInfoAboutVehicle.attributes.longitude, e.bounds)))
      map.panTo({ lat: showInfoAboutVehicle.attributes.latitude, lng: showInfoAboutVehicle.attributes.longitude });

  }, [mapProperties, setMapProperties, showInfoAboutVehicle,]);



  // HANDLE PAN TO VEHICLE
  const handlePanToVehicle = (vehicle: Vehicle) => {
    map.panTo({ lat: vehicle.attributes.latitude, lng: vehicle.attributes.longitude });
    map.setZoom(15)
  }



  // DRAW TRAIN ROUTES
  // if (!polylines.length && map && maps && lines.length) {
  //   let pArray: any[] = [];
  //   for (const line of lines) {
  //     let p = new maps.Polyline({
  //       strokeColor: line.strokeColor,
  //       strokeOpacity: line.strokeOpacity,
  //       strokeWeight: line.strokeWeight,
  //       path: line.stops.map((stop: MbtaStop) => ({
  //         lat: stop.attributes.latitude,
  //         lng: stop.attributes.longitude
  //       }))
  //     });
  //     p.setMap(map);
  //     pArray.push(p);
  //   }
  //   setPolylines(pArray);
  // }



  const handleCloseVehicleCard = React.useCallback(() => setShowInfoAboutVehicle(emptyVehicle), [showInfoAboutVehicle]);


  const checkBoundsAndPanTo = (lat: number | null, lng: number | null) => {
    if (lat && lng)
      map.panTo({ lat, lng });
    else console.log("cannot find your position")
  }



  const buildMarker = (v: Vehicle) => <Marker
    lat={v.attributes.latitude}
    lng={v.attributes.longitude}
    // name={v.relationships.route.data.id}
    key={'map-marker-' + v.id}
    type={v.relationships.route.data.id.match(/[A-Za-z]/)
      ? "vehicle-" + v.relationships.route.data.id.replace(/-.*/, '')
      : 'vehicle'}
    data={v}
    id={v.id}
    text={v.id}
    handleClick={handleMarkerClicked}
    fade={showInfoAboutVehicle.id && showInfoAboutVehicle.id != v.id}
    focus={showInfoAboutVehicle.id && showInfoAboutVehicle.id == v.id}
  />



  const buildCrumb = (c: any, i: number, totalCrumbs: number = 1) => <Crumb
    lat={c.latitude}
    lng={c.longitude}
    key={'map-marker-' + c.latitude + c.longitude + i}
    id={'map-marker-' + c.latitude + c.longitude + i}
    bearing={c.bearing}
    name={c.id}
    status={c.current_status}
    opacity={1 - (i / totalCrumbs)}
    color={c.color}
  />


  const handleDialogClose = React.useCallback(() => setShowRoute(false), [showRoute, setShowRoute]);


  return <>
    <Snackbar
      open={openSnackbar}
      autoHideDuration={2000}
      onClose={() => setOpenSnackbar(false)}
      message={'Viewing ' + (showBuses ? 'Trains' : 'Buses')}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />

    {/* {!openSnackbar &&
      <div style={{ zIndex: 902, position: 'absolute', right: 8, bottom: 20 }} className="fab-cluster">
        <Fab color="secondary" aria-label="add" size="small" onClick={toggleDrawer}>
          <MoreHoriz />
        </Fab>
        <Fab color="primary" aria-label="add" >
          <Search />
        </Fab>
      </div>
    } */}

    <ViewRouteDialog
      route={routes.data[0]}
      open={showRoute}
      onClose={handleDialogClose}
    />

    {showInfoAboutVehicle?.id.length
      ? <VehicleCard
        style={{
          bottom: '18px',
          zIndex: 905,
          right: '6px',
          position: 'absolute',
        }}
        vehicle={showInfoAboutVehicle}
        nextStop={nextStop}
        handleFocus={handleFocusVehicle}
        handleClose={handleCloseVehicleCard}
        handleLocate={handlePanToVehicle}
        handleViewRoute={handleViewRoute}
      />
      : ''}

    <NavBar
      focusedVehicle={focusedVehicle}
      isLockedToVehicle={isLockedToVehicle}
      emptyVehicle={emptyVehicle}
      showBuses={showBuses}
      setIsLockedToVehicle={setIsLockedToVehicle}
      setfocusedVehicle={setFocusedVehicle}
      setShowBuses={setShowBuses}
      numBuses={numBuses}
      numTrains={numTrains}
      toggleDrawer={toggleDrawer}
      clientPosition={clientPosition}
      centerMap={checkBoundsAndPanTo}
      isLocateClientEnabled={isLocateClientEnabled}
      getClientPosition={getClientPosition}
    />

    <div style={{ height: mapHeight + 'px', width: '100%', zIndex: 900, marginTop: mapYOffset, position: 'fixed', bottom: 0 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBxoceyo_38MXTYs6jbWWskYvRlKMHqQ4Q" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={handleMapChange}
        onZoomAnimationStart={handleZoomChange}
        onClick={handleMapClick}
        onGoogleApiLoaded={({ map, maps }) => { setMap(map); setMaps(maps); }}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {/* DRAW VEHICLE MARKERS */}
        {vehicles && vehicles.length && !focusedVehicle.id.length && vehicles.map(buildMarker)}
        {/* DRAW FOCUSED VEHICLE MARKER + CRUMBS */}
        {vehicles && vehicles.length && focusedVehicle.id.length && buildMarker(focusedVehicle)}
        {/* {vehicles && vehicles.length && focusedVehicle.id.length && crumbHistory[focusedVehicle.id]
          .map((c: any, i: number) => i ? buildCrumb(c, i, crumbHistory[focusedVehicle.id].length) : '')} */}
        {vehicles && vehicles.length && showInfoAboutVehicle.id.length && crumbHistory[showInfoAboutVehicle.id]
          .map((c: any, i: number) => i ? buildCrumb(c, i, crumbHistory[showInfoAboutVehicle.id].length) : '')}
      </  GoogleMapReact>
    </div>
  </>;

}


